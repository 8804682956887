import React, { useState, useRef } from "react";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import { ConfigureWrapper } from "./styles";
import { StyledInput } from "../common-components/Input/styles";
import { Tag, Tooltip, Space, Button, Flex } from "antd";
import { PrimaryButton } from "../common-components/PrimaryButton/styles";
import { StyledSelect } from "../common-components/Select/styles";

import {
  PlusCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { Card } from "antd";

const SortableItem = sortableElement(({ value }) => (
  <div className="sortable-item">{value}</div>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <div className="list">{children}</div>;
});

export default function Configure() {
  const [items, setItems] = useState([
    "Track Number",
    "Title",
    "Primary Artist",
    "Secondary Artist",
    "Lyricist",
    "Composer",
    "<<GAP>>",
  ]);

  const [tags, setTags] = useState([]);

  const [inputValue, setInputValue] = useState("");

  const [cards, setCards] = useState([]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {

    if (inputValue) {
      let newTag = { id: "TG" + tags.length, name: inputValue, selected: true };
      let SheetValue = {
        tagId: newTag.id,
        SheetName: "",
        form: [{ db_col: "", xl_col: "" }],
      };
      const updatedTags = tags.map((tag) => ({ ...tag, selected: false }));
      updatedTags.push(newTag);
      setTags(updatedTags);
      setCards([...cards, SheetValue]);
    }
    setInputValue("");
  };

  const getCurrentTagId = () => {
    return tags.find((tag) => tag.selected == true)?.id;
  };

  const onDbColChange = (event, formIndex, cardIndex) => {
    let data = [...cards];
    data[cardIndex].form[formIndex].db_col = event;
    setCards(data);
  };

  const addCard = () => {
    let object = {
      tagId: getCurrentTagId(),
      SheetName: "",
      form: [{ db_col: "", xl_col: "" }],
    };

    setCards([...cards, object]);

  };

  const addFields = (cardIndex) => {
    let data = [...cards];
    data[cardIndex].form.push({ db_col: "", xl_col: "" });
    setCards(data);
  };

  const handleFormChange = (event, formIndex, cardIndex) => {
    let data = [...cards];
    data[cardIndex].form[formIndex].xl_col = event.target.value;
    setCards(data);
  };

  const handleSheetNameChange = (event, index) => {
    let data = [...cards];
    data[index][event.target.name] = event.target.value;
    setCards(data);
  };

  const submit = (e) => {
    e.preventDefault();
  };

  const removeCards = (index) => {
    let data = [...cards];
    data.splice(index, 1);
    setCards(data);
  };

  const removeFields = (formIndex, cardIndex) => {
    let data = [...cards];
    data[cardIndex].form.splice(formIndex, 1);
    setCards(data);
  };

  const updateTagsSelected = (id) => {
    const updatedTags = tags.map((tag) => ({
      ...tag,
      selected: tag.id === id,
    }));

    setTags(updatedTags);
  };

  const handleCloseTag = (removedTag) => {
    // Remove the card associated with the removed tag from the cards state
    const updatedCards = cards.filter((card) => card.tagId !== removedTag);
    setCards(updatedCards);

    // Remove the tag from the tags state
    const updatedTags = tags.filter((tag) => tag.id !== removedTag);
    if(updatedTags.length >= 1 && updatedTags.every(tag => tag.selected == false)){
      updatedTags[0].selected =true;
    }
    setTags(updatedTags);
  };

  return (
    <ConfigureWrapper>
      <div style={{ display: "flex", marginBottom: "15px" }}>
        <div style={{ marginRight: "20px" }}>
          <StyledInput
            type="text"
            style={{ width: "300px" }}
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Type Provider Name..."
            suffix={
              <PlusCircleOutlined
                onClick={handleInputConfirm}
                style={{
                  fontSize: "25px",
                  color: "rgba(0,0,0,.45)",
                  cursor: "pointer",
                }}
              />
            }
          />
        </div>

        <div>
          <Space size={[10, 10]} wrap>
            {tags.length > 0
              ? tags.map((tag, index) => (
                  <PrimaryButton
                    selected={tag?.selected}
                    key={index}
                    size="medium"
                    style={{ width: "100%" }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      updateTagsSelected(tag.id);
                    }}
                  >
                    {tag.name}
                    <CloseCircleOutlined
                      closable
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleCloseTag(tag.id);
                      }}
                      style={{
                        fontSize: "25px",
                        color: "rgba(0,0,0,.45)",
                        cursor: "pointer",
                      }}
                    />
                  </PrimaryButton>
                ))
              : ""}
          </Space>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {cards.map((card, cardIndex) => {
          if (card.tagId === getCurrentTagId()) {
            return (
              <Card
                key={cardIndex}
                style={{
                  width: "30%",
                  margin: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "30px",
                  }}
                >
                  <div></div>

                  <StyledInput
                    type="text"
                    name="SheetName"
                    style={{ width: "50%" }}
                    value={card.SheetName}
                    onChange={(event) =>
                      handleSheetNameChange(event, cardIndex)
                    }
                    placeholder="Sheet_Name"
                  ></StyledInput>

                  <DeleteOutlined
                    style={{ fontSize: "20px", color: "red" }}
                    onClick={() => removeCards(cardIndex)}
                  />
                </div>

                <div>
                  <form onSubmit={submit}>
                    {card.form.map((form, formIndex) => {
                      return (
                        <div
                          key={formIndex}
                          style={{
                            display: "flex",
                            marginTop: "15px",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <StyledSelect
                            name="db_col"
                            className="width-145"
                            placeholder="select from db"
                            defaultValue={form.db_col}
                            onChange={(event) =>
                              onDbColChange(event, formIndex, cardIndex)
                            }
                          >
                            {form.db_col ? null : (
                              <StyledSelect.Option value="" disabled hidden>
                                {"select from db"}
                              </StyledSelect.Option>
                            )}
                            {items.map((item, index) => (
                              <StyledSelect.Option key={index} value={item}>
                                {item}
                              </StyledSelect.Option>
                            ))}
                          </StyledSelect>

                          <StyledInput
                            style={{ width: "40%" }}
                            name="xl_col"
                            placeholder="select from xl"
                            onChange={(event) =>
                              handleFormChange(event, formIndex, cardIndex)
                            }
                            value={form.xl_col}
                          ></StyledInput>
                          <DeleteOutlined
                            style={{ fontSize: "20px", color: "red" }}
                            onClick={() => removeFields(formIndex, cardIndex)}
                          />
                        </div>
                      );
                    })}
                  </form>
                  <div style={{ marginTop: "20%" }}>
                    <PlusCircleOutlined
                      onClick={() => addFields(cardIndex)}
                      style={{
                        fontSize: "30px",
                        color: "rgba(0,0,0,.45)",
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    />
                  </div>
                </div>
              </Card>
            );
          } else {
            return null;
          }
        })}

        <div style={{ display: "flex", alignItems: "center", margin: "10px" }}>
          <PlusCircleOutlined
            onClick={() => addCard()}
            style={{
              fontSize: "30px",
              color: "rgba(0,0,0,.45)",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
      <PrimaryButton onClick={submit}>Submit </PrimaryButton>
    </ConfigureWrapper>
  );
}
