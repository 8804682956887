import React, { useContext, useEffect, useState } from "react";
import { NavButton, NavigationWrapper } from "./styles";
import { Link } from "react-router-dom";
import {
  CloseOutlined,
  LogoutOutlined,
  FolderOutlined,
  FundOutlined,
  PlusCircleOutlined,
  UnorderedListOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import { removeAllCookies } from "../../../utils/auth";
import routes from "../../../routes/routes";
import axios from "axios";
import { AUTH_URL } from "../../../utils/url";
import { Spin } from "antd";
import { getConfig } from "../../../utils/permissions";
import Avatar from "../Avatar";
import { AuthContext } from "../../../context/auth";

export default function Navigation({
  navigationExpanded,
  setNavigationExpanded,
  activeNav,
  setActiveNav,
  setPageTitle,
}) {
  const [logoutInProgress, setLogoutInProgress] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const { loginData } = useContext(AuthContext);

  const navigation = [
    {
      id: 1,
      title: "All Releases",
      icon: <FolderOutlined />,
      route: routes.allReleases,
      hidden: !getConfig("privilege.can-view-all-releases")
    },
    {
      id: 2,
      title: "My Releases",
      icon: <UnorderedListOutlined />,
      route: routes.myReleases,
    },
    {
      id: 3,
      title: "New Release",
      icon: <PlusCircleOutlined />,
      route: routes.newRelease,
    },
    {
      id: 4,
      title: "My Profile",
      icon: <UserOutlined />,
      route: routes.me,
    },
    {
      id: 5,
      title: "Anaytics",
      icon: <FundOutlined />,
      route: routes.analytics,
      disabled: true
    },
    {
      id: 6,
      title: "Users",
      icon: <UsergroupAddOutlined />,
      route: routes.users,
      hidden: !getConfig("privilege.can-configure-application")
    },
    {
      id: 7,
      title: "Configure",
      icon: <SettingOutlined />,
      route: routes.configure,
      hidden: !getConfig("privilege.can-configure-application")
    },
  ];

  const changeActiveNav = (nav) => {
    if (location.pathname === "/" || nav.disabled === true) return;
    setActiveNav(nav.id);
    let activeNavObject = navigation.find((n) => n.id === nav.id);
    setPageTitle(activeNavObject.title);

    const mobileMedia = window.matchMedia("only screen and (max-width:960px)");
    const portraitMedia = window.matchMedia("(orientation: portrait)");

    if (mobileMedia.matches || portraitMedia.matches) {
      setNavigationExpanded(false);
    }
  };

  const logout = async () => {
    try {
      setLogoutInProgress(true);
      const logoutApi = AUTH_URL + "/user/logout";
      await axios.get(logoutApi, { withCredentials: true })
    }
    catch (e) {
      console.error(e);
    }
    finally {
      setLogoutInProgress(false);
      removeAllCookies();
      history.push(routes.root);
    }
  };

  useEffect(() => {
    let path = location.pathname;
    let activeMenu = navigation.find((nav) => nav.route === path);

    if (activeMenu) {
      setActiveNav(activeMenu.id);
      setPageTitle(activeMenu.title);
    }
    //eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (window.visualViewport.width < 960) setNavigationExpanded(false);
    // eslint-disable-next-line
  }, []);

  return navigationExpanded === true ? (
    <NavigationWrapper>
      <div className="navigation-header">
        <div
          className="hamburger-icon"
          onClick={() => setNavigationExpanded(false)}
        >
          <CloseOutlined />
        </div>
        <div className="logo-container">
          <img
            src="https://www.rayapprelease.com/assets/images/logo-main.png"
            alt="RayApp Release"
          ></img>
          <div className="logo-text">Release Hubᴮᴱᵀᴬ</div>
        </div>

        <div className="button-wrapper">
          {navigation.map((n) => {
            if ("hidden" in n && !loginData) {
              return null;
            }
            if (n.hidden) {
              return null;
            }
            const disabled = location.pathname === routes.root || n.disabled === true;
            return (
              <Link to={!disabled ? n.route : "#"} >
                <NavButton
                  active={activeNav === n.id}
                  onClick={() => changeActiveNav(n)}
                  key={n.id}
                  disabled={disabled}
                >
                  {n.icon}&nbsp;&nbsp;{n.title}
                </NavButton>
              </Link>
            );
          })}
        </div>
      </div>
      {loginData && loginData.user._id ? (
        <div className="user-details">
          <span style={{ display: "flex", alignItems: "center" }}>
            Hello,&nbsp;
            <Avatar />
            <Link to={routes.me}>
              <span className="name">&nbsp;
                {(loginData.user.name
                  || loginData.user.firstName + " " + loginData.user.lastName)}
              </span>
            </Link>
          </span>
          {logoutInProgress ?
            <Spin></Spin> :
            <div className="logout-text" onClick={logout}>
              <LogoutOutlined />
              &nbsp;Logout
            </div>}
        </div>
      ) : <div className="user-details"><Spin></Spin></div>}
    </NavigationWrapper>
  ) : null;
}
