import {
    message,
    Modal,
    Input,
    Card,
} from "antd";
import React, { useState } from "react";
import { PrimaryButton } from "../PrimaryButton/styles";
import { StyledSelect } from "../Select/styles";
import axios from "axios";
import { copyTextToClipboard } from "../../../utils/clipboard";
const { TextArea } = Input;

export default function AdminAlbumControls({ album, setAlbum, tracks }) {
    const [visible, setVisible] = React.useState(false);
    const [reviewComments, setReviewComments] = useState(album.reviewComments);
    const [status, setStatus] = useState(album.status);

    const StatusArr = [
        "Draft",
        "Submitted",
        "Approved",
        "Released",
        "Rejected",
        "SentToStores",
        "ReSubmitted",
        "TakenDown",
    ];

    const onStatusChange = (status) => {
        setStatus(status);

        if (status === "Rejected") {
            setVisible(true);
        } else {
            submitStatus(status);
        }
    };

    const submitStatus = (status) => {
        axios
            .patch("/admin/albums/" + album._id + "/status", {
                status: status,
                reviewComments: status === "Rejected" ? reviewComments : "",
            })
            .then(() => {
                message.success(
                    "Album status for " + album.title + " has been set to " + status
                );
                setVisible(false);
                setAlbum((album) => {
                    const copy = { ...album };
                    if (status === "Rejected") copy.reviewComments = reviewComments;
                    else copy.reviewComments = "";
                    return copy;
                });
            })
            .catch((e) => {
                message.error("Failed changing album status for " + album.title);
            });
    };

    const setComments = (comments) => {
        console.log(comments.target.value);
        setReviewComments(comments.target.value);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const copyExcelRows = () => {
        let excelRows = tracks.map((track, index) => {
            return [
                index + 1, // track number
                track.title, // track title
                "", // version
                index + 1, // cd number
                album.title, // release title
                track.primaryArtist, // primary artists
                track.secondaryArtist, // featuring artists
                "", // remix artists
                track.lyricist, // authors
                track.composer, // composers
                album.label, // label
                album.productionYear, // production year
                album.label, // production owner
                album.label, // copyright owner
                track.mainGenre, // genre
                track.subGenre, // sub genre
                tracks.length === 1 ? "Single" : "Album", // track type
                track.language, // lyrics language
                track.language, // title language
                track.explicit ? "Yes" : "No", // parental advisory
                "", // territories
                "", // release price tier
                "", // tracks per price tier
                "", // publisher,
                "", // digital release date
                "", // physical release date
                "", // sample start index
                track.ISRC, // ISRC
                album.UPC, // UPC
            ];
        });
        excelRows = excelRows.map((lines) => lines.join("\t")).join("\n");
        console.log(excelRows);
        copyTextToClipboard(excelRows).then(
            function () {
                console.log("Async: Copying to clipboard was successful!");
                message.success("Excel rows copied successfully!");
            },
            function (err) {
                console.error("Async: Could not copy text: ", err);
                message.success("Error copying excel rows.");
            }
        );
    };

    return (
        <Card title="Moderation">
            <div style={{ display: 'flex' }}>
                <div style={{ width: 200 }}>
                    <StyledSelect
                        showSearch
                        style={{ width: 100 }}
                        placeholder="Select a status"
                        optionFilterProp="children"
                        defaultValue={album.status}
                        onChange={(s) => onStatusChange(s)}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {album.status}
                        {StatusArr.map((status) => (
                            <StyledSelect.Option key={status} value={status}>{status}</StyledSelect.Option>
                        ))}
                    </StyledSelect>
                </div>

                <Modal
                    title="Rejected Comments"
                    visible={visible}
                    onOk={() => submitStatus(status)}
                    onCancel={handleCancel}
                >
                    <TextArea
                        onChange={setComments}
                        defaultValue={album.reviewComments}
                        value={reviewComments}
                        rows={4}
                    />
                </Modal>

                <div style={{ paddingLeft: 15 }}>
                    <PrimaryButton onClick={copyExcelRows}>Copy Excel Rows</PrimaryButton>
                </div>
            </div>
        </Card>
    );
}
