import React, { useEffect, useRef, useState } from "react";
import { AdminReleaseListWrapper } from "./styles";
import { Link } from "react-router-dom";
import {
  notification,
  Table,
  Input,
  Button,
  Space,
  message,
} from "antd";
import axios from "axios";
import moment from "moment";
import Highlighter from "react-highlight-words";
import { StyledSelect } from "../common-components/Select/styles";

import {
  EyeOutlined,
  EditOutlined,
  SearchOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import routes from "../../routes/routes";
import { PrimaryButton } from "../common-components/PrimaryButton/styles";
import { getConfig } from "../../utils/permissions";
import { convertObject } from "../../utils/common";

const StatusArr = [
  "Draft",
  "Submitted",
  "Approved",
  "Released",
  "SentToStores",
  "TakenDown",
  "ReSubmitted",
];

export default function AllReleases({ setPageTitle }) {
  const [tableLoading, setTableLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [releaseList, setReleaseList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [state, setState] = useState({
    searchText: "",
    searchedColumn: "",
  });
  const [showStatusDropDown, setShowStatusDropDown] = useState(true);
  const searchInput = useRef();

  useEffect(
    () =>
      setPageTitle("All Releases"),
    [totalCount, setPageTitle]
  );

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const downloadAlbums = () => {
    axios
      .post("/admin/albums/download-bulk", { albums: selectedRowKeys })
      .then((response) => {
        console.log(response.data);
      });
  }

  const getAllReleases = (limit, offset, filters) => {
    setTableLoading(true);
    let searchStrings = JSON.stringify(convertObject(filters))
    axios
      .get("/admin/albums", { params: { limit: limit, offset: offset, searchFields: searchStrings } })
      .then((response) => {
        let albums = response.data.data.albums;

        if (albums.length) {
          albums = albums.map((a) => {
            a.key = a._id;
            a.uploadedBy = a.user[0].name || a.user[0].firstName + " " + a.user[0].lastName;
            a.creatorEmail = a.user[0].email;
            a.creatorMobile = a.user[0].phoneCountryCode ? a.user[0].phoneCountryCode + " " + a.user[0].phone : a.user[0].phone;
            return a;
          });
          setReleaseList(albums);
          setTotalCount(response.data.data.totalCount);
        } else {
          setReleaseList([]);
        }
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  useEffect(() => {
    let getPagination = sessionStorage.getItem("currentPage")
    if (getPagination) {
      let pagination = JSON.parse(getPagination)
      handleChange(pagination, null, {})
    } else {
      getAllReleases(10, 0, {});
    }
    // eslint-disable-next-line
  }, []);

  const getData = (offset, limit, params, filters) => {
    getAllReleases(limit, offset, filters);
  };

  const handleChange = (pagination, filters, sorter) => {
    sessionStorage.setItem("currentPage", JSON.stringify(pagination));
    const offset =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const limit = pagination.pageSize;
    const params = {};

    if (sorter.hasOwnProperty("column")) {
      params.order = { field: sorter.field, dir: sorter.order };
    }

    getData(offset, limit, params, filters);
  };

  const onStatusChange = (status, album, index) => {
    axios
      .patch("/admin/albums/" + album._id + "/status", {
        status: status,
        reviewComments: "",
      })
      .then(() => {
        setReleaseList((albums) => {
          const albumsCopy = [...albums];
          albumsCopy[index].status = status;
          return albumsCopy;
        });
        message.success(
          "Album status for " + album.title + " has been set to " + status
        );
      })
      .catch(() => {
        message.error("Failed changing album status for " + album.title);
      });

    setReleaseList((oldReleaseList) => {
      const albumIndex = oldReleaseList.findIndex((r) => r._id === album._id);
      const newList = [...oldReleaseList];
      newList[albumIndex].status = album.status;
      return newList;
    });
    console.log(status, album);
  };

  const onBulkStatusChange = (status, selected) => {
    console.log(selected);
    axios
      .patch("/admin/albums/status", {
        status: status,
        albums: selected,
      })
      .then(() => {
        setReleaseList((albums) => {
          const albumsCopy = [...albums];

          albumsCopy.forEach((album, index) => {
            console.log(albums, album._id);
            if (selected.includes(album._id)) {
              albumsCopy[index].status = status;
            };
          })
          return albumsCopy;
        });
        setShowStatusDropDown(false);
        setTimeout(() => setShowStatusDropDown(true), 10);
        setSelectedRowKeys([]);
        message.success(
          "Updated album status to " + status
        );
      })
      .catch(() => {
        message.error("Failed changing album status");
      });
  };

  const deleteAlbum = (album) => {
    if (
      window.prompt(
        `Type "${album.title || album.fingerprint}" to delete this album`
      ) !== album.title &&
      album.fingerprint
    )
      return;

    notification.info({
      message: "Deleting album...",
    });
    axios
      .delete("/admin/albums/" + album._id)
      .then(() => {
        notification.success({
          message: "Album deleted",
        });
        setReleaseList((rl) => rl.filter((r) => r._id !== album._id));
      })
      .catch(() => {
        notification.error({
          message: "Error deleting album",
        });
      });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: "" });
  };

  const columns = [
    {
      title: "Release Id",
      key: "fingerprint",
      dataIndex: "fingerprint",
      ...getColumnSearchProps("fingerprint")
    },
    {
      title: "Actions",
      key: "actions",
      render: (album) => (
        <div className="release-actions">
          <Link to={routes.viewRelease + "/" + album._id}>
            <div className="action-button">
              <span>
                <EyeOutlined />
                &nbsp;
              </span>
            </div>
          </Link>
          <Link to={routes.editRelease + "/" + album._id}>
            <div className="action-button">
              <span>
                <EditOutlined />
                &nbsp;
              </span>
            </div>
          </Link>
        </div >
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status, album, index) =>
        tableLoading || !showStatusDropDown ? (
          <div style={{ height: 40, width: 200 }}></div>
        ) : (
          <StyledSelect
            showSearch
            style={{ width: 200 }}
            placeholder="Select a status"
            optionFilterProp="children"
            defaultValue={status}
            onChange={(s) => onStatusChange(s, album, index)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {status}
            {StatusArr.map((status) => (
              <StyledSelect.Option value={status}>
                {(() => {
                  switch (status) {
                    case "Released":
                      return <span style={{ color: "#00BFA5" }}>{status}</span>;
                    case "SentToStores":
                      return <span style={{ color: "#F06292" }}>{status}</span>;
                    case "Approved":
                      return <span style={{ color: "#F9A825" }}>{status}</span>;
                    default:
                      return status;
                  }
                })()}
              </StyledSelect.Option>
            ))}
          </StyledSelect>
        ),
    },
    {
      title: "Release Title",
      key: "title",
      dataIndex: "title",
      ...getColumnSearchProps("title"),
      render: (title, album) => (
        <Link to={routes.viewRelease + "/" + album._id}>
          <div className="release-meta">
            <div className="release-title">
              {title || (
                <span>
                  <i>No information available</i>
                </span>
              )}
            </div>
          </div>
        </Link>
      ),
    },
    {
      title: "Primary Artist",
      key: "primaryArtist",
      dataIndex: "primaryArtist",
      render: (primaryArtist) => primaryArtist,
      ...getColumnSearchProps("primaryArtist")
    },
    {
      title: "Uploaded By",
      key: "uploadedBy",
      dataIndex: "uploadedBy",
      render: (uploadedBy) => uploadedBy
    },
    {
      title: "UPC",
      key: "UPC",
      dataIndex: "UPC",
      render: (UPC) => UPC,
      ...getColumnSearchProps("UPC")
    },
    {
      title: "Release Date",
      key: "releaseDate",
      dataIndex: "releaseDate",
      render: (releaseDate) => moment(releaseDate).format("MMMM Do YYYY"),
    },
    {
      title: "Created Date",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (releaseDate) => moment(releaseDate).format("MMMM Do YYYY"),
    },
    {
      title: "Creator Email",
      key: "creatorEmail",
      dataIndex: "creatorEmail",
      render: (creatorEmail) => creatorEmail
    },
    {
      title: "Creator Mobile",
      key: "creatorMobile",
      dataIndex: "creatorMobile",
      render: (creatorMobile) => creatorMobile
    },
  ];

  if (getConfig("privilege.can-delete-releases")) {
    columns.push({
      title: "Delete",
      key: "delete",
      render: (album) => (
        <div className="release-actions">
          <div className="action-button danger">
            <span onClick={() => deleteAlbum(album)}>
              <DeleteOutlined />
              &nbsp;
            </span>
          </div>
        </div>
      ),
    });
  }

  return (
    <AdminReleaseListWrapper>
      {hasSelected ?
        <div className="bulk-actions">
          <StyledSelect
            showSearch
            style={{ width: 200 }}
            placeholder="Select a status"
            optionFilterProp="children"
            onChange={(s) => onBulkStatusChange(s, selectedRowKeys)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {StatusArr.map((status) => (
              <StyledSelect.Option value={status}>
                {(() => {
                  switch (status) {
                    case "Released":
                      return <span style={{ color: "#00BFA5" }}>{status}</span>;
                    case "SentToStores":
                      return <span style={{ color: "#F06292" }}>{status}</span>;
                    case "Approved":
                      return <span style={{ color: "#F9A825" }}>{status}</span>;
                    default:
                      return status;
                  }
                })()}
              </StyledSelect.Option>
            ))}
          </StyledSelect>
          <StyledSelect
            showSearch
            placeholder="Select provider"
            optionFilterProp="children"
            style={{ marginLeft: 8 }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <StyledSelect.Option value={'believe'}>Believe</StyledSelect.Option>
          </StyledSelect>
          <PrimaryButton onClick={downloadAlbums} style={{ marginLeft: 8 }}>
            Download ({selectedRowKeys.length})
          </PrimaryButton>
        </div>
        : null
      }
      <Table
        rowSelection={rowSelection}
        rowKey="key"
        columns={columns}
        dataSource={releaseList}
        pagination={{
          disabled: tableLoading,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} releases`,
          showQuickJumper: true,
          current: (() => {
            return sessionStorage.getItem("currentPage") ? JSON.parse(sessionStorage.getItem("currentPage")).current : 1
          })(),
          pageSize: sessionStorage.getItem("currentPage") ? JSON.parse(sessionStorage.getItem("currentPage")).pageSize : 10,
          defaultPageSize: 10,
          total: totalCount,
        }}
        loading={tableLoading}
        scroll={{ x: "max-content" }}
        onChange={handleChange}
        size="small"
      />
    </AdminReleaseListWrapper >
  );
}
