const routes = {
  root: "/",
  newRelease: "/new-release",
  editRelease: "/edit-release",
  myReleases: "/my-releases",
  allReleases: "/all-releases",
  viewRelease: "/view-release",
  analytics: "/analytics",
  me: "/me",
  configure: "/configure",
  user: "/user",
  users: "/users",
  termsOfRelease: "/terms-of-release",
};

export default routes;
